import React, {useContext} from 'react'
import { SpinnerContext } from "../../context/spinnerContext";

const GlobalLoading = props => {
    const {loading} = useContext(SpinnerContext)

    return loading ? (
      <div className="globalLoading">       
<div className="ctn-preloader">
        <div className="animation-preloader">
          <div className="spinner"></div>
          <div className="txt-loading">
            <span data-text-preloader="L" className="letters-loading"> L </span>
            <span data-text-preloader="o" className="letters-loading"> o </span>
            <span data-text-preloader="a" className="letters-loading"> a </span>
            <span data-text-preloader="d" className="letters-loading"> d </span>
            <span data-text-preloader="i" className="letters-loading"> i </span>
            <span data-text-preloader="n" className="letters-loading"> n </span>
            <span data-text-preloader="g" className="letters-loading"> g </span>
          </div>
          <p className="text-center">Please wait ..</p>
        </div>
      </div>
  </div>
    ) :  null
  }
  
  export default GlobalLoading