import React, { useEffect, useState } from "react";
import { Col, Row, Container, Accordion, Card, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Service from "../services/Service";
import Img from '../components/common/image'
function People(props) {
  const { name } = props.match.params;
  const [data, setData] = useState(null);
  let history = useHistory();
  useEffect(() => {
    Service.getTable(name)
      .then((rsp) => {
        setData(rsp.data);
      })
      .catch((e) => {
        console.log(e);
      });
      return function cleanup(){
        
      }
  }, [name]);

  return data ? (
    <Container className="mt-3">
      <Row>
        <Col md={10}>
          <h1 className="text-center h2 mb-2">{data.title}</h1>
        </Col>
        <Col md={2}>
          <button
            className="btn btn-secondary float-right mb-2"
            onClick={() => history.goBack()}
          >
            <i className="icon-arrow-left"></i> Back ..
          </button>
        </Col>
      </Row>

      <Row>
        <Col>
          {data ? (
            <div>
              <div className="border-top p-md-3 ">
                <Accordion>
                  {data.rows.map((r, i) => {
                    return (
                      <Card
                        key={`Card-${i}`}
                        className="mb-2 border-bottom border-gray"
                      >
                        <Card.Header className="bg-light">
                          <div className="d-md-flex flex-md-row">
                            <div className="p-md-2 text-center">
                              <Img
                                src={`${process.env.REACT_APP_RESOURCEURL}${r.Photo}`}
                                style={{ width: "72px", height: "89px" }}
                                alt="mora"
                              ></Img>
                            </div>
                            <div className="p-md-2">
                              <h4>
                                {" "}
                                {r.စဉ်}. {r.title}
                              </h4>
                              <h6>{r.လက်ရှိသီတင်းသုံးရာကျောင်းတိုက်}</h6>
                              <h6>{r.တယ်လီဖုန်း}</h6>
                            </div>
                            <div className="flex-md-fill">
                              <Accordion.Toggle
                                className="mt-3 float-right"
                                as={Button}
                                variant="primary"
                                eventKey={`A-${i}`}
                              >
                                အသေးစိတ် ဖော်ပြချက်
                              </Accordion.Toggle>
                            </div>
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey={`A-${i}`}>
                          <Card.Body>
                            <table className="table table-borderless">
                              <tbody>
                                {Object.keys(r).map((key, j) => {
                                  return !data.hideColumn.includes(key) ? (
                                    <tr key={j}>
                                      <th className="w-25" scope="row">
                                        {key}
                                      </th>
                                      <td className="w-75">{r[key]}</td>
                                    </tr>
                                  ) : null;
                                })}
                              </tbody>
                            </table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
                </Accordion>
              </div>
            </div>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  ) : null;
}

export default People;
