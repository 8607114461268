import React, { useEffect, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { generatePath } from "react-router";
import { Col, Row, Container, Carousel } from "react-bootstrap";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Service from "../services/Service";
import Sidebar from "../components/widgets/sidebar";
import {SpinnerContext} from '../context/spinnerContext'
import Img from '../components/common/image'
function Post(props) {
  const {setLoading} = useContext(SpinnerContext)
  const { id } = props.match.params;
  const [data, setData] = useState(null);
  let history = useHistory();
  useEffect(() => {
    setLoading(true)
    Service.getPost(id)
      .then((rsp) => {
        setData(rsp.data);
        window.scrollTo(0, 0);
        setLoading(false)
      })
      .catch((e) => {
        console.log(e);
        setLoading(false)});
        return function cleanup(){
        
        }
  }, [id]);

  return (
    data !== null ?

      data.Template === "common_pdf" ? 
      <Container>
          <Row style={{marginTop: "30px"}}>
                <Col md={10}><h1 className="text-center h2 mb-2">{data.Title}</h1></Col>
              <Col md={2} className="mb-2">
              <button
                className="btn btn-secondary float-right"
                onClick={() => history.goBack()}
              >
                <i className="icon-arrow-left"></i> Back ..
              </button>
              </Col>
              
              </Row>
        <Row>
          <iframe src={data.Metas.attachment} style={{width: "100%", height: "1000px", marginBottom: "30px"}}></iframe></Row></Container>
             
                :  <>
      <section className="bg-breadcrumb p-3  mb-4">
        <Container>
          <Row>
            <Col lg={10}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={generatePath(`/Home`)} className="text-light">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link
                      to={generatePath(`/post/${data.Id}`)}
                      className="text-light"
                    >
                      {data.Title}
                    </Link>
                  </li>
                </ol>
              </nav>
            </Col>
            <Col lg={2}>
              <button
                className="btn btn-secondary float-right"
                onClick={() => history.goBack()}
              >
                <i className="icon-arrow-left"></i> Back ..
              </button>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row>
          <Col md={9}>
            {data ? (
              <div className="p-2 p-md-5 bg-light border-top border-bottom shadow border-warning mb-5 rounded">
                <h1 className="h3 text-secondary mb-4">{data.Title}</h1>
                <div className="d-flex justify-content-lg-between my-3">
                  <div>
                    <span className="p-1 text-secondary">
                      <i className="icon-docs text-capitalize"> <Link to={generatePath(`/list?type=${data.Type}`)}>{data.Type}</Link> </i>
                    </span>
                  </div>
                  <div>
                    <span className="p-1 text-secondary">
                      <i className="icon-calendar"> {data.CreateAt}</i>
                    </span>
                  </div>
                  <div>
                    <span className="p-1 text-secondary">
                      <i className="icon-user"> {data.AuthorName}</i>
                    </span>
                  </div>
                  
                </div>

                {data.Images ? (
                  <Carousel>
                    {" "}
                    {data.Images.map((i,j) => {
                      return (
                        <Carousel.Item key={j}>
                          <Img className="d-block w-100" src={i} />
                        </Carousel.Item>
                      );
                    })}{" "}
                  </Carousel>
                ) : data.FeatureImages ? (
                  <div className="m-auto">
                    <Img
                      src={data.FeatureImages}
                      className="rounded p-1 border"
                      style={{ width: "100%" }}
                    ></Img>
                  </div>
                ) : (
                  ""
                )}
   <LazyLoadComponent> 
                <div
                  className="mt-4"
                  dangerouslySetInnerHTML={{ __html: data.Body }}
                />
                {data.Categories ? (
                    <div>
                      <span className="p-1 text-secondary">
                        <i className="icon-folder text-capitalize"></i>{" "}
                        {data.Categories.map((c, i) => {
                          return (
                            <Link
                              key={i}
                              to={generatePath(
                                `/list?category=${c}&page=1&limit=8`
                              )}
                              className="text-capitalize"
                            >
                              {i !== 0 ? `, ${c}` : c}
                            </Link>
                          );
                        })}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </LazyLoadComponent> 
              </div>
              
            ) : (
              ""
            )}
          </Col>
          <Col md={3}>
            <Sidebar settings={props.settings}></Sidebar>
          </Col>
        </Row>
      </Container>
    </>
    : null
  );
}

export default Post;
