import React, { useState, useEffect } from "react";
import Card from "../common/card";
import MediaWidget from "../common/mediaWidget";
import Service from "../../services/Service";
import PaginationForWidget from "../common/paginationForWidget";

function RecentWidget(props) {
  const limit = 3;
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  function onPageChanged(pagingData) {
    const { currentPage } = pagingData;
    setPage(currentPage);
  }

  useEffect(() => {
    setLoading(true);
    Service.getPosts({ type: props.type, page: page, limit: limit })
      .then((rsp) => {
        if (rsp.data.Meta.TotalRecords !== totalRecords) {
          setTotalRecords(rsp.data.Meta.TotalRecords);
        }
        setData(rsp.data.Data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [page]);

  return data.length !== 0 ? (
    <Card {...props} loading={loading}>
      <div style={{ position: "relative" }}>
        <div
          className={`w-100 h-100 position-absolute bg-opacity-dark ${
            loading ? "d-block" : "d-none"
          }`}
        >
          {/* <div class="lds-hourglass position-absolute"  style={{top: "35%", left:"40%"}}></div> */}
          {/* <div class="lds-grid position-absolute"  style={{top: "35%", left:"40%"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> */}
          <div
            className="lds-ripple position-absolute"
            style={{ top: "25%", left: "35%" }}
          >
            <div></div>
            <div></div>
          </div>
        </div>

        {data.map((post, i) => {
          return <MediaWidget key={i} post={post}></MediaWidget>;
        })}

        <div className={!loading ? "d-block" : "d-none"}>
          <PaginationForWidget
            totalRecords={totalRecords}
            pageLimit={limit}
            pageNeighbours={1}
            onPageChanged={onPageChanged}
          />
        </div>
      </div>
    </Card>
  ) : (
    ""
  );
}

export default RecentWidget;
