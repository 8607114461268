import Api from "../gateway/api";

const getPosts = ({search,type, category, tags, area, author, year, month, day, page, limit}) => { 
    let queryArr = [];
    if (search) {queryArr.push(`search=${search}`)}
    if (type) {queryArr.push(`type=${type}`)}
    if (category) {queryArr.push(`category=${category}`)}
    if (tags) {queryArr.push(`tags=${tags}`)}
    if (area) {queryArr.push(`area=${area}`)}
    if (author) {queryArr.push(`author=${author}`)}
    if (year) {queryArr.push(`year=${year}`)}
    if (month) {queryArr.push(`month=${month}`)}
    if (day) {queryArr.push(`day=${day}`)}
    if (page) {queryArr.push(`page=${page}`)}
    if (limit) {queryArr.push(`limit=${limit}`)}  

    if (queryArr.length !== 0){
        let query = queryArr.join("&");               
        return Api.get(`/posts?${query}`)
    }
    else{
        return Api.get(`/posts`)
    }    
};

const getPost = (id) => {
  return Api.get(`/posts?id=${id}`);
};

const getNav = (dept, name) => {
    return Api.get(`/nav?dept=${dept}&name=${name}`);
};

const getCategory = () => {
    return Api.get(`/cats`);
};

const getTags = () => {
    return Api.get(`/tags`);
};

const getSlider = (dept, name) => {
    return Api.get(`/slider?dept=${dept}&name=${name}`);
};

const getSettings = () =>{
    return Api.get(`/settings`);
}


const getTable = (name) =>{
    return Api.get(`/table?name=${name}`);
}

const authenticate = (apiKey) =>{
    return Api.get(`/Authenticate?key=${apiKey}`);
}



export default { 
    getPosts,
    getPost,
    getNav,
    getCategory,
    getTags,
    getSlider,
    getSettings,
    getTable,
    authenticate
};

// Optionally the request above could also be done as
