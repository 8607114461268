import React, { useState, useEffect } from "react";
import Service from "../../services/Service";

import { Carousel } from "react-bootstrap";

function DhammaQuote(props) {
  const limit = 4;
  const [data, setData] = useState([]);


  useEffect(() => {
 
    Service.getPosts({
      type: "dhammaquota",
      category: "Today Dhamma Notes",
      page: 1,
      limit: limit,
    })
      .then((rsp) => {
  
        setData(rsp.data.Data);
      })
      .catch((e) => {
        console.log(e);
      });
      return function cleanup(){
        
      }
  }, []);

  return data.length !== 0 ? (
    <div className="dhammaQuota bg-dhammaQuote text-light">
      <Carousel>
        {data.map((post,i) => {
          return (
            <Carousel.Item key={i} className="p-4">
              <h4 className="mb-4">{post.Title}</h4>
              <p dangerouslySetInnerHTML={{ __html: post.Body.replace(/<[^>]+>/g, '') }}/>
              <span className="float-right">{post.Summary}</span>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  ) : (
    ""
  );
}

export default DhammaQuote;
