import React from "react";
import {Carousel} from 'react-bootstrap'
import Img from './image'

export default function PhotoSlider(props) {    
    return (        
        <Carousel>
          {
            props.Slides.map((s, i)=>{              
              return <Carousel.Item key={i}>
              <Img
                className="d-block w-100"
                src={`${process.env.REACT_APP_RESOURCEURL}${s.image}`}
                alt={s.title}
              />          
            </Carousel.Item>      
            })
          }
      </Carousel>
    );  
}
