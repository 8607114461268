import React from 'react'

import { Row, Container } from "react-bootstrap";
function Section(props) {
    return (
        <section {...props}>
            <Container>
                <Row>
                    {props.children}
                </Row>
            </Container>
        </section>
    )
}


export default Section

