import React, {useState} from "react";
import { Media, Collapse } from "react-bootstrap";
import {Link} from "react-router-dom";
import Img from './image'


function MediaWidget(props) {
  const { post } = props;
  const [open, setOpen] = useState(false);


  //Id, Type, Title, Body, Summary, FeatureImages, Categories, CreateAt, AuthorName
  return (
    <Media key={post.Id} className="border-bottom p-3 d-flex flex-column cMedia">
      
      <Media.Body className="widgetBody d-flex flex-row">
        <Img className="border rounded align-self-center mr-3"
          width={120}
          height={82}
          alt="MORA"          
          src={post.Thumbnails ? post.Thumbnails : `${process.env.REACT_APP_RESOURCEURL}/media/static/Thumbnail.png`} onError={(e)=>{e.target.onerror = null; e.target.src=`${process.env.REACT_APP_RESOURCEURL}/media/static/Thumbnail.png`}}
        />
        <Collapse in={open}>        
          <Link className="align-self-center text-secondary ml-3" to={{pathname: `/post/${post.Id}`}}>{post.Title}</Link>
        </Collapse>        
      </Media.Body>
      
      <div className="mediaFooter d-flex justify-content-around w-100 mt-2 text-secondary">
          <div>
            <a href=".#"><i className="icon-user"></i> {post.AuthorName}</a>
          </div>
          <div>
            <a href=".#"><i className="icon-calendar"></i> {post.CreateAt}</a>
          </div>
          {
              post.Title.length >= 90 ? <div><a onClick={() => setOpen(!open)} aria-controls="title" aria-expanded={open} style={{border: 0, width: 0, height: 0}}></a></div> : ""
          }          
        </div>
    </Media>
  );
}

export default MediaWidget;
