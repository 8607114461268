import React from "react";
import { Link } from "react-router-dom";
import {
  Nav,
  Navbar,
  NavDropdown,
  Container,
} from "react-bootstrap";
import Sticky from "react-stickynode";


function SubMenuBuilder(s,i) {
  return !s.children ? (
    <NavDropdown.Item key={i} as={Link} to={s.href}>       
      {s.text}
    </NavDropdown.Item>
  ) : (
    <NavDropdown key={i} title={s.text}>
      {s.children.map((ss, j) => {         
        return SubMenuBuilder(ss, j);
      })}
    </NavDropdown>
  );
}

function MenuBuilder(m,i) {
  return m.children ? (
    <NavDropdown key={i} title={m.text}>      
      {m.children.map((s,j) => {
        return SubMenuBuilder(s,j);
      })}
    </NavDropdown>
  ) : (
    <Nav.Link key={i} as={Link} to={m.href}>      
      {m.text}
    </Nav.Link>
  );
}

function NavBar(props) {
  const { settings: settings, ...rest } = props;
  return (
    <Sticky top={0} innerZ={11} activeClass="navbar_fixed">
      <header {...rest} >
        <Container>
          <Navbar id="primary-nav" variant="dark" className="py-2 py-md-0" expand="lg">
            <Navbar.Brand href="#home" className="d-md-none d-block">
              {settings.ShortTitle}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="menu">
              <Nav className="mr-auto">
              {settings.NavigationMenu.map((m, i)=>{                
                return MenuBuilder(m, i);
              })}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>

      </header>
    </Sticky>
  );
}

export default NavBar;





// <Nav.Link as={Link} href="#home">
// <i className="icon-home font-weight-bold"></i>
// </Nav.Link>
// <Nav.Link as={Link} href="#home">
// ဝန်ကြီးရုံး(သာသနာရေး)
// </Nav.Link>
// <NavDropdown title="သံဃာ့အဖွဲ့အစည်းအဆင့်ဆင့်">
// <NavDropdown.Item as={Link} href="#action/3.1">
//   ရည်ရွယ်ချက်
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.2">
//   ရွေးချယ်တင်မြောက်ခြင်း
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.3">
//   ဖွဲ့စည်းဆက်သွယ်ပုံဇယား
// </NavDropdown.Item>
// <NavDropdown.Divider />
// <NavDropdown.Item href="#action/3.4">
//   နိုင်ငံတော်သံဃမဟာနာယကအဖွဲ့
// </NavDropdown.Item>
// <NavDropdown.Item href="#action/3.4">
//   သံဃာ့အဖွဲ့အစည်းကြီးပေါ်ပေါက်လာပုံ
// </NavDropdown.Item>
// </NavDropdown>

// <NavDropdown title="ဦးစီးဌာနများ">
// <NavDropdown.Item as={Link} href="#action/3.1">
//   သာသနာရေးဦးစီးဌာန
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.2">
//   သာသနာတော်ထွန်းကားပြန့်ပွားရေးဦးစီးဌာန
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.3">
//   အပြည်ပြည်ဆိုင်ရာထေရဝါဒဗုဒ္ဓသာသနာပြုတက္ကသိုလ်
// </NavDropdown.Item>
// <NavDropdown title="ဦးစီးဌာနများ">
//   <NavDropdown.Item as={Link} href="#action/3.1">
//     သာသနာရေးဦးစီးဌာန
//   </NavDropdown.Item>
//   <NavDropdown.Item as={Link} href="#action/3.2">
//     သာသနာတော်ထွန်းကားပြန့်ပွားရေးဦးစီးဌာန
//   </NavDropdown.Item>
//   <NavDropdown.Item as={Link} href="#action/3.3">
//     အပြည်ပြည်ဆိုင်ရာထေရဝါဒဗုဒ္ဓသာသနာပြုတက္ကသိုလ်
//   </NavDropdown.Item>
//   <NavDropdown title="ဦးစီးဌာနများ">
//     <NavDropdown.Item as={Link} href="#action/3.1">
//       သာသနာရေးဦးစီးဌာန
//     </NavDropdown.Item>
//     <NavDropdown.Item as={Link} href="#action/3.2">
//       သာသနာတော်ထွန်းကားပြန့်ပွားရေးဦးစီးဌာန
//     </NavDropdown.Item>
//     <NavDropdown.Item as={Link} href="#action/3.3">
//       အပြည်ပြည်ဆိုင်ရာထေရဝါဒဗုဒ္ဓသာသနာပြုတက္ကသိုလ်
//     </NavDropdown.Item>
//   </NavDropdown>
// </NavDropdown>
// </NavDropdown>

// <NavDropdown title="စာမေးပွဲအောင်စာရင်းများ">
// <NavDropdown.Item as={Link} href="#action/3.1">
//   အဘိဓမ္မာနှင့်ဝိသုဒ္ဓိမဂ်စာမေးပွဲများ
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.2">
//   နိကာယ်စာမေးပွဲများ
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.3">
//   ပါဠိပထမပြန်စာမေးပွဲများ
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.3">
//   ဓမ္မာစရိယစာမေးပွဲများ
// </NavDropdown.Item>
// <NavDropdown.Item as={Link} href="#action/3.3">
//   တိပိဋကဓရရွေးချယ်ရေးစာမေးပွဲ
// </NavDropdown.Item>
// </NavDropdown>

// <Nav.Link as={Link} href="#link">
// Contact
// </Nav.Link>