import React from "react";
import Img from "../common/image"
const buddhaTalks = {
  backgroundStyle: {
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    position: "absolute",
    opacity: 0.6,
  },
  listStyle: {
    listStyle: "none",
    fontSize: "1.3em",
    lineHeight: "40px"
  },
};

const BuddhaTalk = () => {
  return (
    <>
      <Img
        src={`${process.env.REACT_APP_RESOURCEURL}/media/static/buddha.png`}
        className="ml-auto"
        style={buddhaTalks.backgroundStyle}
        alt="MORA"
      ></Img>
   
        <div className="p-3 text-secondary">
          <h2 className="mb_10 text-center">
            ဘုရားရှင်၏ သြဝါဒ
          </h2>
        </div>

        <div>
          <ul
            style={buddhaTalks.listStyle}
            className="text-secondary"
          >
            <li>မကောင်းမှုမှန်သမျှ မပြုလုပ်ခြင်း</li>
            <li>ကောင်းမှုမှန်သမျှ ပြည့်စုံစေခြင်း</li>
            <li>မိမိစိတ်ကို ဖြူစင်စေခြင်း</li>
          </ul>
        </div>
        <div className="p-2">
          <p className="text-secondary p-1 text-center font-weight-bold border-secondary border rounded">
            ဤတရားသုံးပါးသည် ခပ်သိမ်းသော ဘုရားရှင်တို့၏ အဆုံးအမ သြဝါဒပေတည်း။
          </p>
        </div>

    </>
  );
};


export default BuddhaTalk;