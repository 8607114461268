import React, { useRef } from "react";
import { FormControl } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const { settings: settings, ...rest } = props;
  const searchbox = useRef("");
  let history = useHistory();

  function KeyUp(e) {
    var enterKey = 13; //Key Code for Enter Key
    if (e.which === enterKey) {
      if (searchbox.current.value.length > 1) {
        history.push(`/list?search=${searchbox.current.value}&page=1&limit=8`);
      }
    }
  }

  return (
    <header {...rest}>
      <div className="container">
        <div className="d-none d-md-inline-flex align-items-center p-2">
          <div>
            <img
              src={process.env.REACT_APP_RESOURCEURL + settings.Logo}
              width="90px"
            ></img>
          </div>
          <div className="ml-2">
            <h2 className={`h5 f_600 text-${props.color}`}>
              {settings.TagsLine}
            </h2>
            <h1 className={`h3 f_700 text-${props.color}`}>{settings.Title}</h1>
          </div>
        </div>
  
        <div className="float-right p-2 d-flex flex-column">
          <div className="mb-3 d-none d-lg-block">
            <input
              ref={searchbox}
              className="searchBox"
              type="search"
              onKeyUp={KeyUp}
              placeholder="Search .."
            />
          </div>
          <div className="d-none d-lg-block">
            <a
              href={settings.Facebook}
              target="_blank"
              style={{ color: "#3b5998" }}
            >
              <img
                src="https://img.icons8.com/fluent/48/000000/facebook-new.png"
                width="28px"
                height="28px"
              />{" "}
              Facebook
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
