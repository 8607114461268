/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import {Row, Col, Container} from 'react-bootstrap'
import BuddhaTalk from "./buddhaTalk";
import PhotoSlider from "./photoSlider";

export default function(props){
    const {settings, ...rest} = props;
    return(
      <section className="bg-heroback">
      <Container {...rest}>
        <Row className="no-gutters">
          <Col className="bg-hero d-none d-lg-block">
            <BuddhaTalk></BuddhaTalk>
          </Col>
          <Col lg={8} md={12}>
            <PhotoSlider Slides={settings.Slider}></PhotoSlider>
          </Col>
        </Row>
      </Container>
      
      </section>
    )
}

