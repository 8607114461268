import React from 'react'
import { Link, useLocation, useHistory } from "react-router-dom";
import PDFViewer from 'pdf-viewer-reactjs'

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
export default function Pdf() {
    let query = useQuery()
  
    return (
        query.has("url") ? 
        <PDFViewer
            document={{
                url: query.get("url"),
            }}
        />
        : 
        <div>Invalid URL</div>
    )
}
