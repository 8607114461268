import React, { useState } from "react";

import Calendar from "react-calendar";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "react-calendar/dist/Calendar.css";

function PostsByCalendarTree(props) {
  let history = useHistory();
  const [value, onChange] = useState(new Date());
  const [yearMode, setYearMode] = useState(true);

  function handleChange() {
    setYearMode(!yearMode);
  }

  return (
    <div className="shadow">
      <Calendar
        onChange={onChange}
        value={value}
        defaultView="decade"
        maxDetail={yearMode ? "decade" : "year"}
      />
      <div className="d-flex flex-row pt-2 justify-content-around">
        <div>
          <Form.Group controlId="calendarMode">
            <Form.Check
              type="checkbox"
              checked={yearMode}
              onChange={handleChange}
              type="switch"
              label={yearMode ? "နှစ်အလိုက်" : "လအလိုက်"}
            />
          </Form.Group>
        </div>
        <div>
          <Button
            size="sm"
            onClick={() => {
              history.push(
                yearMode
                  ? `/list?year=${value.getFullYear()}&page=1&limit=8`
                  : `/list?year=${value.getFullYear()}&month=${value.getMonth()}&page=1&limit=8`
              );
            }}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PostsByCalendarTree;
