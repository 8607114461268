import React, {useState} from "react";
import Header from "../components/header";
import NavBar from "../components/navBar";
import Footer from "../components/footer";
import { SpinnerContext } from "../context/spinnerContext";
import GlobalLoading from "../components/widgets/globalLoading";
const AppLayout = (props) => { 
  const {settings, children} = props;
  const [loading, setLoading] = useState(false)
  return (    
    
     <SpinnerContext.Provider value={{loading,setLoading}}>
        <GlobalLoading></GlobalLoading>
      <div className="body_wrapper">   
        <div className="s_round one d-none d-md-block"></div>
        <div className="s_round two d-none d-md-block"></div>
    

        <Header className="bg-header" settings={settings} color="secondary"></Header>      

        <NavBar className="bg-nav-header" settings={settings}></NavBar>        
          {children}     
        <Footer className="mt-3" settings={settings}></Footer>      
      </div>
      </SpinnerContext.Provider>
    
  );
};

export default AppLayout;
