import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from 'prop-types'

function CustomCard(props) {
  return (
    <Card {...props}>
      <Card.Body className="p-0">
        <Card.Title className="bg-card-header m-0 p-3 text-normal text-white"><i className={props.icon}></i> {props.title} {props.loading ? <span className="float-right">Loading ...</span> : "" }</Card.Title>
        {props.children}
      </Card.Body>      
    </Card>
  );
}


CustomCard.propTypes = {
    loading: PropTypes.bool,
    icon: PropTypes.string,
    title: PropTypes.string
}


export default CustomCard;
