import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';


class ScrollToTopRoute extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.path === this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
     
    }
  }

  render() {    
    const { component: Component,layout: Layout, settings: Settings, ...rest } = this.props;

    

    return <Route {...rest} render={props => (<Layout settings={Settings}><Component settings={Settings} {...props} /></Layout>)} />;
  }
}

export default withRouter(ScrollToTopRoute);