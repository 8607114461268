import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import PDFViewer from 'pdf-viewer-reactjs'
import Service from "../services/Service";
import {SpinnerContext} from '../context/spinnerContext'
function Page(props) {
  const {setLoading} = useContext(SpinnerContext)
  const { id } = props.match.params;
  const [data, setData] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  let history = useHistory();
  useEffect(() => {
    setLoading(true)
    Service.getPost(id)
      .then((rsp) => {
        console.log(rsp.data)
        setData(rsp.data);
        window.scrollTo(0, 0);
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e);
      });
      return function cleanup(){
        
      }
  }, [id]);

  return (
    
    data !== null ?
    <Container className="mt-3">      
          {data ? (
            <div>
              <Row>
                <Col md={10}><h1 className="text-center h2 mb-2">{data.Title}</h1></Col>
              <Col md={2} className="mb-2">
              <button
                className="btn btn-secondary float-right"
                onClick={() => history.goBack()}
              >
                <i className="icon-arrow-left"></i> Back ..
              </button>
              </Col>
              
              </Row>
              <Row>
                {data.Template === "common_pdf" ? 
                 <iframe src={data.Metas.attachment} style={{width: "100%", height: "1000px", marginBottom: "30px"}}></iframe>
             
                : 
                  <LazyLoadComponent> 
                    <Col className="border-top p-4" dangerouslySetInnerHTML={{__html: data.Body}} />
                </LazyLoadComponent>
                }
              
              </Row>              
            </div>
          ) : (
            ""
          )}
      
    </Container>
         : null   
  );
}

export default Page;
