import React from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import Img from "./image"

function SinglePostWidget(props) {
  const { post } = props;

  //Id, Type, Title, Body, Summary, FeatureImages, Categories, CreateAt, AuthorName
  return (
    <div className="border-bottom p-3 d-flex flex-row bg-white mb-3">
      <div className="w-25 align-self-center">
        <Img
          className="border rounded mr-3"
          width="100%"
          alt="MORA"
          src={
            post.Thumbnails
              ? post.Thumbnails
              : `${process.env.REACT_APP_RESOURCEURL}/media/static/Thumbnail.png`
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `${process.env.REACT_APP_RESOURCEURL}/media/static/Thumbnail.png`;
          }}
        />
      </div>
      <div className="w-75 p-3">
        <div className="w-100 mb-2 d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
             <div className="mb-3">
            <i className="icon-calendar"></i> {post.CreateAt}{" "}
          </div>
          <div>
            <Link
              to={generatePath(`/list?type=${post.Type}&page=1&limit=8`)}
              className="text-capitalize"
            >
              <i className="icon-docs"></i> {post.Type}
            </Link>
          </div>
          <div>
            <Link to={{ pathname: `/profile/${post.AuthorName}` }}>
              <i className="icon-user"></i> {post.AuthorName}
            </Link>
          </div>
          </div>
          
          <div  className="mb-3">
            <Link
              className="align-self-center text-secondary"
              to={generatePath(`/post/${post.Id}`)}
            >
              {post.Title}
            </Link>
          </div>
        </div>

        <div className="w-100 d-flex justify-content-between text-secondary">
         
          
          {post.Categories ? (
            <div>
              <i className="icon-folder "></i>{" "}
              {post.Categories.map((c, i) => {
                return (
                  <Link
                    key={i}
                    to={generatePath(`/list?category=${c}&page=1&limit=8`)}
                    className="text-capitalize"
                  >
                    {i !== 0 ? `, ${c}` : c}
                  </Link>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default SinglePostWidget;
