import React from 'react'
import Category from './category'
import PostsByCalendarTree from './postsByCalendarTree'


function Sidebar(props) {    

    return (
        <>
        <div className="mb-4 shadow">
            <PostsByCalendarTree></PostsByCalendarTree>
        </div>

        <div className="mb-4 shadow px-3 pt-4 bg-white">
            <Category settings={props.settings}></Category>
        </div>
        </>
    )
}



export default Sidebar

