import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

const NotFoundPage = () => {
  let history = useHistory();
  return (
    <Container>
      <Row>
        <Col>          
          <div className="text-center p-5">
          <img style={{width: "50%", marginBottom: "30px"}}
            src={`${process.env.REACT_APP_RESOURCEURL}/media/static/404.png`}
            alt=""
          />
            <h2>Error. We can’t find the page you’re looking for.</h2>
            <p>
              Sorry for the inconvenience. Go to our homepage or check out our
              latest News, Events, Announcements.
            </p>
         
            <button onClick={() => history.goBack()} className="btn btn-danger">
              Back to Previous Page <i className="icon-arrow-right"></i>
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
