import React from "react";
import { Col } from "react-bootstrap";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import Hero from "../components/common/hero";
import RecentWidget from "../components/widgets/recentWidget";
import PostsByCalendarTree from "../components/widgets/postsByCalendarTree";
import ShortCutBox from "../components/widgets/shortCutBox";
import DhammaQuote from "../components/widgets/dhammaQuote";
import ExamQuery from "../components/widgets/examQuery";
import Section from "../components/common/section";
import Img from '../components/common/image';

function Home(props) {
  return (
    <>
      <Hero className="mb-5 d-none d-md-block" settings={props.settings}></Hero>

      <Section className="mt-3 mt-md-0">
        <Col lg={6} md={6}>
          <RecentWidget
            type="announcement"
            icon="icon-bell"
            title="ကြေညာချက်များ"
            className="mb-5 shadow"
          ></RecentWidget>
        </Col>

        <Col lg={6} md={6}>
          <RecentWidget
            icon="icon-docs"
            type="news"
            title="သတင်းများ"
            className="mb-5 shadow"
          ></RecentWidget>
        </Col>        
      </Section>

      <Section>
        <Col md={8} className="mb-4 mb-md-0">
        <RecentWidget
            icon="icon-docs"
            type="post"
            title="ဆောင်းပါးများ"
            className="mb-5 shadow"
          ></RecentWidget>
          {/* <ExamQuery></ExamQuery> */}
        </Col>
        <Col md={4} className="mb-4 mb-md-0">
          <PostsByCalendarTree></PostsByCalendarTree>
        </Col>
      </Section>

      <Section className="pt-5">
        <Col md={4} className="d-flex flex-column mb-4 mb-md-0">
          <div>
            <Img
              src={`${process.env.REACT_APP_RESOURCEURL}/media/static/kbrl_building.jpg`}
              width="100%"
              height="180px"
              className="rounded-top"
              alt="MORA"
              style={{zIndex: 5}}
            ></Img>
          </div>
          <div
            style={{ marginTop: "-40px", background: "rgba(0,0,0, 0.6)", zIndex: 6 }}
            className="p-2 text-white text-center"
          >
            <a
              className="text-white"
              href="http://www.kbrl.gov.mm/"
              target="_blank"
              rel="noreferrer"
            >
              ကမ္ဘာအေးဗုဒ္ဓ သုတေသနစာကြည့်တိုက်
            </a>
          </div>
        </Col>

        <Col md={4} className="d-flex flex-column mb-4 mb-md-0">
          <Img
            src={`${process.env.REACT_APP_RESOURCEURL}/media/static/bookshop.jpg`}
            width="100%"
            height="180px"
            className="rounded-top"
            alt="MORA"
            style={{zIndex: 5}}
          ></Img>

          <div
            style={{
              marginTop: "-40px",
              bottom: 0,
              background: "rgba(0,0,0, 0.4)",
              zIndex: 6
            }}
            className="p-2 text-white text-center"
          >
            <Link className="text-white" to={generatePath("/page/4113")}>
              ကမ္ဘာအေးစာပုံနှိပ်တိုက်
            </Link>
          </div>
        </Col>

        <Col md={4} className="d-flex flex-column  mb-4 mb-md-0">
          <Img
            src={`${process.env.REACT_APP_RESOURCEURL}/media/static/worldBuddhismSubmit.jpg`}
            width="100%"
            height="180px"
            className="rounded-top"
            alt="MORA"
            style={{zIndex: 5}}
          ></Img>

          <div
            style={{
              marginTop: "-40px",
              bottom: 0,
              background: "rgba(0,0,0, 0.4)",
              zIndex: 6
            }}
            className="p-2 text-white text-center"
          >
            <Link className="text-white" to={generatePath("/page/4112")}>
              The World Buddhism Submit
            </Link>
          </div>
        </Col>
      </Section>

      <Section className="pt-5">
        <Col className="mb-4">
          <DhammaQuote></DhammaQuote>
        </Col>
      </Section>

      <Section className="bg-sc pt-5">
        <Col className="mb-5">
          <ShortCutBox
            className="bg-sc1"
            header="Tender လုပ်ငန်းများ"
            desc="တင်ဒါ လုပ်ငန်းများ နှင့် သက်ဆိုင်သော ကြေငြာချက်များ"
            icon="icon-envelope-letter"
            link="/list?category=tender&page=1&limit=8"
          ></ShortCutBox>
        </Col>

        <Col className="mb-5">
          <ShortCutBox
            className="bg-sc2"
            header="စာမေးပွဲ အောင်စာရင်းများ"
            desc=" စာမေးပွဲ သက်ဆိုင်သော ကြေငြာချက်များ"
            icon="icon-graduation"
            link="/list?category=exam&page=1&limit=8"
          ></ShortCutBox>
        </Col>

        <Col className="mb-5">
          <ShortCutBox
            className="bg-sc3"
            header="လျှောက်လွှာပုံစံများ"
            desc="လုပ်ငန်းနှင့် သက်ဆိုင်သော လျှောက်လွှာပုံစံများ"
            icon="icon-docs"
            link="/list?category=form&page=1&limit=8"
          ></ShortCutBox>
        </Col>
      </Section>
    </>
  );
}

export default Home;

{
  /* <BasicRules></BasicRules>       */
}
