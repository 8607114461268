import React, { useState, useEffect, useContext } from "react";
import { generatePath } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import Service from "../services/Service";
import SinglePostWidget from "../components/common/SinglePostWidget";
import PaginationForPage from "../components/common/paginationForPage";

import { SpinnerContext } from "../context/spinnerContext";

function makePath(query) {
  //PathFields
  let breadcrumb = ["type", "category", "year", "month", "day", "search"];
  return Object.keys(query).map((k, i) => {
    if (breadcrumb.includes(k)) {
      return makeLink(k, query[k], i);
    }

    return null;
  });
}

function makeLink(key, value, i) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <li key={i} className="breadcrumb-item">
      <Link to={generatePath(`/list?${key}=${value}`)}>
        {key === "month"
          ? monthNames[value]
          : value.charAt(0).toUpperCase() + value.slice(1)}
      </Link>
    </li>
  );
}

function loadPostQuery(query) {
  //search, type, category, tags, area, author, year, month, day, page, limit

  let q = {};

  if (query.has("type")) {
    q.type = query.get("type");
  }

  if (query.has("category")) {
    q.category = query.get("category");
  }

  if (query.has("year")) {
    q.year = query.get("year");
  }
  if (query.has("month")) {
    q.month = query.get("month");
  }
  if (query.has("day")) {
    q.day = query.get("day");
  }

  if (query.has("search")) {
    q.search = query.get("search");
  }

  if (query.has("tags")) {
    q.tags = query.get("tags");
  }

  if (query.has("area")) {
    q.area = query.get("area");
  }
  if (query.has("author")) {
    q.author = query.get("author");
  }

  if (query.has("page")) {
    q.page = parseInt(query.get("page"));
  } else {
    q.page = 1;
  }
  if (query.has("limit")) {
    q.limit = parseInt(query.get("limit"));
  } else {
    q.limit = 9;
  }

  return q;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Exam(props) {
  let history = useHistory();
  const query = loadPostQuery(useQuery());
  const { page, limit, ...rest } = query;
  const [totalRecords, setTotalRecords] = useState(0);
  const [data, setData] = useState(null);
  const { loading, setLoading } = useContext(SpinnerContext);

  const [IsChange, setIsChange] = useState(false);

  props.history.listen(() => {
    setIsChange(!IsChange);
  });

  const fetchData = (mounted) => {
    setLoading(true);
    Service.getPosts(query)
      .then((rsp) => {
        if (rsp.data.Meta.TotalRecords !== totalRecords) {
          setTotalRecords(rsp.data.Meta.TotalRecords);
        }
        setData(rsp.data.Data);
        window.scrollTo(0, 0);
        if (mounted) {
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let mounted = true;
    fetchData(mounted);
    return function cleanup() {
      mounted = false;
      setIsChange(false);
    };
  }, [IsChange]);

  return data !== null && data.length !== 0 ? (
    <>
      <section className="bg-breadcrumb p-3 mb-4">
        <Container>
          <Row>
            <Col lg={5}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li key="home" className="breadcrumb-item">
                    <Link to={generatePath(`/Home`)} className="text-light">
                      Home
                    </Link>
                  </li>
                  {makePath(query)}
                </ol>
              </nav>
            </Col>

            <Col lg={3}>
              <div className="p-2 text-center text-white">
                Total Records : {totalRecords}
              </div>
            </Col>
            <Col lg={4}>
              <div className="p-2 text-right  text-white">
                Page: {page} / {Math.ceil(totalRecords / limit)}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col className="d-flex flex-row justify-content-between border-bottom">   
                <div className="flex-fill"><h2 className="text-center">စာမေးပွဲအောင်စာရင်းများ</h2></div>            
                <div className="float-right">
                  <PaginationForPage
                    totalRecords={parseInt(totalRecords)}
                    pageNeighbours={1}
                    pageNumber={page}
                    limit={limit}
                    query={rest}                  
                  ></PaginationForPage>
                </div>
              </Col>
            </Row>

            {data.map((post, i) => {
              return <SinglePostWidget key={i} post={post}></SinglePostWidget>;
            })}

            <div>
              <PaginationForPage
                totalRecords={parseInt(totalRecords)}
                pageNeighbours={1}
                pageNumber={page}
                limit={limit}
                query={rest}
              ></PaginationForPage>
            </div>
          </Col>
          
        </Row>
      </Container>
    </>
  ) : (
    <Container>
      <Row>
        <Col>
          <div className="text-center p-5">
            <img
              style={{ width: "50%", marginBottom: "30px" }}
              src={`${process.env.REACT_APP_RESOURCEURL}/media/static/404.png`}
              alt=""
            />
            <h2>Error. No results found.</h2>
            <p>We couldn't find what you are looking for</p>

            <button onClick={() => history.goBack()} className="btn btn-danger">
              Back to Previous Page <i className="icon-arrow-right"></i>
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Exam;
