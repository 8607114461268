import React from "react";
import GoogleMapReact from "google-map-react";
import { Container, Row, Col } from "react-bootstrap";

const AnyReactComponent = ({ text }) => <div style={{width: "300px"}}><i className="icon-location-pin text-danger" style={{fontSize: "20pt"}}></i>{text}</div>;

function footer(props) {
 
  const createMapOptions = (maps) => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: [{
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#c9b2a6" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [{ color: "#dcd2be" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ae9e90" }],
      },
      {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#FCE6C4" }],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#FCE6C4" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#93817c" }],
      },
      {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [{ color: "#a5b076" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#447530" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#f5f1e6" }],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{ color: "#fdfcf8" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#f8c967" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#e9bc62" }],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [{ color: "#e98d58" }],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [{ color: "#db8555" }],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#806b63" }],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#FCE6C4" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8f7d77" }],
      },
      {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ebe3cd" }],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#FCE6C4" }],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#b9d3c2" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#92998d" }],
      }]
    }
  }
  
  const defaultProps = {
    center: {
      lat: 19.813615866235207,
      lng: 96.14127784839371,
    },
    zoom: 14,
  };
  
  return (
    <section className="bg-footer pt-4">
        <div style={{position: "relative"}}>
        <img style={{position: "absolute", bottom: "-390px", opacity: '0.5', width: '100%', height: '300px'}} alt="MORA" src={`${process.env.REACT_APP_RESOURCEURL}/media/static/footer.png`}></img>
        </div>
      <Container>
        
    
        <Row>
        <Col md={3} className="p-4 bg-opacity">
            <h4 className="mb-4 border-bottom border-secondary text-secondary"><i className="icon-paper-plane"></i> Address</h4>
            <div className="mt-2">
              <div className="text-center">
                <img src={`${process.env.REACT_APP_RESOURCEURL}/media/static/qr.png`} className="p-1 border m-auto" width="130px;" alt="qrCode"></img>
              </div>
              <ul className="list-unstyled mt-3" style={{lineHeight: "40px"}}>
                <li className="text-secondary"><i className="icon-location-pin"></i> {props.settings.Address}</li>
                {/* <li><a href={`tel:${props.settings.Phone}`} className="text-secondary"><i className="icon-phone"></i> {props.settings.Phone}</a></li> */}
                <li><a href={`mailto:${props.settings.Email}`} className="text-secondary"><i className="icon-envelope-open"></i> {props.settings.Email}</a></li>
              </ul>
            </div>
          </Col>
        <Col md={3} className="p-4">
            <h4 className="mb-4 border-bottom border-secondary text-secondary"><i className="icon-badge"></i> About Us</h4>
            <div className="mt-2">              
                <p>{props.settings.ContactNote}</p>              
            </div>
          </Col>
      
          <Col md={3}  className="p-4">
            <h4 className="mb-4 border-bottom border-secondary text-secondary"><i className="icon-globe"></i> Our Websites</h4>
            
              <ul className="list-unstyled">
              <li><a href="http://www.dra.gov.mm" target="_blank" className="text-secondary">သာသနာရေးဦးစီးဌာန</a></li>
              <li><div  className="border-secondary border-top my-1"></div></li>                
                <li><a href="http://www.dpps.gov.mm"  target="_blank" className="text-secondary">သာသနာတော်ထွန်းကားပြန့်ပွားရေးဦးစီးဌာန</a></li>
                <li><div  className="border-secondary border-top my-1"></div></li>
                <li><a href="http://www.itbmu.org.mm" target="_blank" className="text-secondary">အပြည်ပြည်ဆိုင်ရာထေရဝါဒဗုဒ္ဓသာသနာပြုတက္ကသိုလ်</a></li>
                
              </ul>
            
          </Col>
          <Col md={3} className="pb-3">
            <div className="rounded border-primary" style={{ height: "370px", width: "100%" }}>
            {" "}
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyD6Z4nXAWXH7kiLYQYNlJwyWLU5j4TyiIo",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}   
              options={createMapOptions}        
            >
              <AnyReactComponent
                lat={props.settings.Latitude}
                lng={props.settings.Longitude}
                text="Ministry of Religious Affairs and Culture (Religious)"
              />
            </GoogleMapReact>
            </div>
          </Col>
        </Row>
      </Container>
      <footer
        className="bg-footer-2 text-white text-center p-2"
        style={{ height: "50px" }}
      >
        © {new Date().getFullYear()} {props.settings.Title} 
        &nbsp;	&nbsp;	&nbsp;	&nbsp;	 Visitor Count : <a style={{"position": "absolute","marginLeft": "35px"}} href="https://www.freecounterstat.com" title="hit counter">
          <img src="https://counter2.stat.ovh/private/freecounterstat.php?c=rlk7tpkz7yglz4gujbg2xac7ezj9l3u9" border="0" title="hit counter" alt="hit counter" />

          </a>

        {/* | <a href="https://www.crystal-image.net" target="_blank">Powered by Crystal Image Co.,Ltd</a> */}
      </footer>
    </section>
  );
}

export default footer;
