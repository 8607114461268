import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { generatePath } from "react-router";
import { Link, useLocation } from "react-router-dom";
import utils from "../../utils/Utility";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

const getLinkWithQuery = (nextPage, query, limit) => {
  let _q = query;
  _q.page = nextPage;
  _q.limit = limit;
  return generatePath("/list?" + utils.objectToQueryString(_q));
};

const fetchPageNumbers = (totalPages, pageNumber, pageNeighbours) => {
  const totalNumbers = pageNeighbours * 2 + 3;
  const totalBlocks = totalNumbers + 2;

  if (totalPages > totalBlocks) {
    let pages = [];

    const leftBound = pageNumber - pageNeighbours;
    const rightBound = pageNumber + pageNeighbours;
    const beforeLastPage = totalPages - 1;

    const startPage = leftBound > 2 ? leftBound : 2;
    const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

    pages = range(startPage, endPage);

    const pagesCount = pages.length;
    const singleSpillOffset = totalNumbers - pagesCount - 1;

    const leftSpill = startPage > 2;
    const rightSpill = endPage < beforeLastPage;

    const leftSpillPage = LEFT_PAGE;
    const rightSpillPage = RIGHT_PAGE;

    if (leftSpill && !rightSpill) {
      const extraPages = range(startPage - singleSpillOffset, startPage - 1);
      pages = [leftSpillPage, ...extraPages, ...pages];
    } else if (!leftSpill && rightSpill) {
      const extraPages = range(endPage + 1, endPage + singleSpillOffset);
      pages = [...pages, ...extraPages, rightSpillPage];
    } else if (leftSpill && rightSpill) {
      pages = [leftSpillPage, ...pages, rightSpillPage];
    }

    return [1, ...pages, totalPages];
  }

  return range(1, totalPages);
};

function PaginationForPage(props) {
  const {
    totalRecords = null,
    pageNeighbours = 1,
    pageNumber = 0,
    limit = 8,
    query = {},
 
  } = props;
  const totalPages = Math.ceil(totalRecords / limit);
  const pages = fetchPageNumbers(totalPages, pageNumber, pageNeighbours);

  return (
    <Fragment>
      <nav aria-label="Pagination">
        <ul className={`pagination d-flex justify-content-center mt-2`}>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <>
                <li key={index} className="page-item">
                  <Link
                    to={getLinkWithQuery(
                      pageNumber - parseInt(pageNeighbours) * 2 - 1,
                      query,
                      limit
                    )}
                    className="page-link"
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </Link>
                </li>
              
              </>
              );

            if (page === RIGHT_PAGE)
              return (
                <li key={index} className="page-item">
                  <Link
                    to={getLinkWithQuery(
                      pageNumber + parseInt(pageNeighbours) * 2 + 1,
                      query,
                      limit
                    )}
                    className="page-link"
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </Link>
                </li>
              );
                   
                        return (
                          <li
                            key={index}
                            className={`page-item${pageNumber === page ? " active" : ""}`}
                          >
                            <Link
                              to={getLinkWithQuery(page, query, limit)}
                              className="page-link"
                            >
                              {page}
                            </Link>
                          </li>
                        );
                      
                     
           
          })}
        </ul>
      </nav>
    </Fragment>
  );
}

PaginationForPage.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  pageNeighbours: PropTypes.number,
  query: PropTypes.object,
};

export default PaginationForPage;
