import React, { useLayoutEffect, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Service from "./services/Service";

import "./style/saas/pre-loader.css";
import "./style/app.scss";

import "./style/simple-line-icons.css";

import "./style/animate.css";
// import "./style/elegent.css";
// import "./style/font-awesome.css";
// import "./style/themify-icons.css";
// import "./style/main.css";
// import "./style/responsive.css";

import ScrollToTopRoute from "./utils/ScrollToTopRoute";

import AppLayout from "./layouts/layout";
import Home from "./pages/home";
import Post from "./pages/post";
import Page from "./pages/page";
import Pdf from "./pages/pdf";
import List from "./pages/list";
import Exam from "./pages/exam";
import People from "./pages/people";
import NotFoundPage from "./pages/404";
import ComingSoon from "./pages/comingSoon";


function App(props) {
  const [settings, setSettings] = useState(null);
 
  useEffect(() => {
    Service.getSettings()
      .then((rsp) => {
        setSettings(rsp.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  //First Authenticate

  //Store Authenticate Token to State


  useLayoutEffect(() => {
    props.hideLoader();
  }, []);

  return settings != null ? (
    <Router>    

      <Switch>     
          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/Home"
            component={Home}
            settings={settings}
          />
          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/Index"
            component={Home}
            settings={settings}
          />
          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/"
            component={Home}
            settings={settings}
          />
          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/post/:id"
            component={Post}
            settings={settings}
          />
          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/page/:id"
            component={Page}
            settings={settings}
          />

<ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/pdf"
            component={Pdf}
            settings={settings}
          />

          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/list"
            component={List}
            settings={settings}
          />

<ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/exam"
            component={Exam}
            settings={settings}
          />

          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/people/:name"
            component={People}
            settings={settings}
          />
          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/ComingSoon"
            settings={settings}
            component={ComingSoon}
          />

          <ScrollToTopRoute
            exact
            layout={AppLayout}
            path="/404"
            settings={settings}
            component={NotFoundPage}
          />
          <Redirect to="/404"></Redirect>
          
      </Switch>
    
    </Router>
  ) : null;
}

export default App;
