import React from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

function makeCatLink(c,i) {
  return (
    <li key={`${c.title}-i`} className="py-1">
      <Link to={generatePath(`/list?category=${c.title}&page=1&limit=8`)}>
        <i className="icon-arrow-right" style={{fontSize: "8pt"}}></i> {c.title}
      </Link>
      {c.subs ? (
        <ul key={`ul-${i}}`} className="list-unstyled ml-3">
          {c.subs.map((s,j) => {
            return makeCatLink(s,j);
          })}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
}

function Category(props) {
  const { Categories } = props.settings;

  return (
    <>
      <h4 className="text-secondary">Categories</h4>

      <div className="p-2">
        <ul className="list-unstyled">
          {Categories.map((c,i) => {
            return makeCatLink(c,i);
          })}
        </ul>
      </div>
    </>
  );
}

export default Category;
