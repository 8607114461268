import React from "react";
import {Link, generatePath} from "react-router-dom";
function ShortCutBox(props) {
  return (
    <div className={`p-3 shadow ${props.className}`}>
    
    <Link className="p-3 d-flex text-light" to={generatePath(props.link)}>
      <div style={{fontSize: "35px"}}>
        <i className={props.icon} ></i>
      </div>
      <div className="p-2">
      <h4 className="h5">{props.header}</h4>        
      </div>      
    </Link>
  </div>
  );
}

export default ShortCutBox;
